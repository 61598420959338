import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ 'modal' ];
  connect() {
    this.element.addEventListener( 'turbo:submit-end', ( event ) => {
      if ( event.detail.success ){
        document.body.classList.remove( 'modal-open' );
        this.element.removeAttribute( 'style' );
        this.element.classList.remove( 'show' );
        document.getElementsByClassName( 'modal-backdrop' )[ 0 ].remove();
      }
    } );
  }
}
