import { Controller } from '@hotwired/stimulus';
import { create_flatpickr } from 'form_helpers';

export default class extends Controller {
  connect() {
    $( '#actor-modal' )
    .on( 'show.bs.modal', function(){
      $( '#search, #search_id, #search_name, #search_type, #sel-MfgCompanyType, #search_path' ).val( '' );
    } )
    .on( 'shown.bs.modal', function(){
      $( '#search' ).focus();
    } )
    .on( 'hide.bs.modal', function(){
      $( '#search' ).removeClass( 'is-invalid' );
      $( '#sel-MfgCompanyType' ).removeClass( 'is-invalid' );
      $( '#error-text-search' ).text( '' );
    } );

    create_flatpickr( [
      $( '.start-date-group' ),
      $( '.end-date-group' ),
    ] );

    // Disable the delete block if the length is equal to 1
    if( $( '.del-block' ).length == 1 ){ $( '.del-block' ).hide(); }

    // Remove the href attribute if it is blank
    $( '.actor_box' ).find( 'a' ).each( function(){
      if( $( this ).attr( 'href' ) == '' ){
        $( this ).removeAttr( 'href' );
      }
    } );
  }
}
