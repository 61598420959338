import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "name", "output" ];

  connect() {
    console.log( "hello from StimulusJS" );
  }

  greet() {
    this.outputTarget.value = `Hello, ${this.nameTarget.value}!`;
  }
}
