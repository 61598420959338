import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  intercept( event )  {
    const { url } = event.detail.fetchResponse.response;
    if ( url.match( "/u/login" ) ) {
      // Intercept unauthorized redirect in turbo frame request.
      event.preventDefault();
      // To redirect the user back after a successful authorization. The url before signing out
      // here, is the turbo frame request, which is part of the the current page.
      window.location = window.location.href;
    }
  }
}
