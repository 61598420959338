const $ = window.$;
import 'datatables.net';
import 'datatables.net-bs5';
import 'datatables.net-buttons';
import 'datatables.net-buttons-bs5';
import 'datatables.net-responsive';
import 'datatables.net-responsive-bs5';
import 'datatables.net-rowgroup';
import 'datatables.net-rowgroup-bs5';
import 'datatables.net-buttons/js/buttons.colVis.min';
import 'datatables.net-buttons/js/buttons.html5.min';

"use strict";
export const dataTablesDefault = {
  buttons: {
    dom: {
      button: {
        className: 'btn btn-sm btn-light fw-semibold dropdown-toggle'
      },
      container: {
        className: 'btn-toolbar'
      },
      collection: {
        className: 'dropdown-menu'
      }
    },
    buttons: [
      {
        extend: 'colvis',
        titleAttr: 'Column visibility',
        text: 'Column visibility',
        background: false,
        fade: 0,
        prefixButtons: [
          {
            text: 'Select all',
            init: function( dt, node ) {
              var column = dt.columns().visible().toArray();
              // Set the initial state of the "Select all"
              $( node ).toggleClass( 'dt-button-active', column.every( Boolean ) );
              $( node ).toggleClass( 'indeterminate', !column.every( Boolean ) && column.some( Boolean ) );
            },
            action: function( e, dt ) {
              var column = dt.columns().visible().toArray();
              // Toggle visibility of all columns based on their current state
              dt.columns().visible( !column.every( Boolean ) );
              $( dt.table().container() ).find( '.select-all' ).removeClass( 'indeterminate' );
              $( dt.table().container() ).find( '.select-all' ).toggleClass( 'dt-button-active', !column.every( Boolean ) );
            },
            className: 'buttons-columnVisibility select-all'
          }
        ],
      },
      {
        extend: 'csvHtml5',
        text: '<span class="svg-icon svg-icon-3 me-1"><svg class="icon-download"><use xlink:href="#icon-download"></use></svg></span> Export',
        exportOptions: {
          columns: 'thead th:not(.no-export)',
          format: {
            body: function( data, row, column, node ) {
              if( typeof $( node ).data( 'val' ) !== 'undefined' ) {
                data = $( node ).data( 'val' );
              } else {
                data = data.replace( /(<([^>]+)>)/ig, '' ).trim();
                data = data.replace( /<br\s*\/?>/ig, '\r\n' );
                data = data.replace( /<.*?>/g, '' );
                data = data.replace( '&amp;', '&' );
                data = data.replace( '&nbsp;', '' );
                data = data.replace( '&nbsp;', '' );
              }
              return data;
            }
          }
        }
      }
    ]
  },
  dom:
  "<'row invisible'" +
    "<'col-md-12 col-lg-6 d-flex align-items-center justify-content-center justify-content-lg-start mb-4'f>" +
    "<'col-md-12 col-lg-6 d-flex align-items-center justify-content-center justify-content-lg-end mb-4'B>" +
  ">" +
  "<'row invisible d-flex flex-fill flex-column'" +
    "<'table-responsive d-flex flex-fill flex-column'rt>" +
  ">" +
  "<'row invisible'" +
    "<'col-md-12 col-lg-5 d-flex align-items-center justify-content-center justify-content-lg-start mt-4'i>" +
    "<'col-md-12 col-lg-7 d-flex align-items-center justify-content-center justify-content-lg-end mt-4'lp>" +
  ">",
  language: {
    lengthMenu: 'Display &nbsp; _MENU_',
    search: 'Search ',
    paginate: {
      first: '<i class="kt-outline kt-double-left"></i>',
      last: '<i class="kt-outline kt-double-right"></i>',
      next: '<i class="next"></i>',
      previous: '<i class="previous"></i>'
    },
    processing: '<span class="loader"><span class="spinner-border h-15px w-15px align-middle text-primary"></span><span class="text-gray-800 fw-medium ms-4">Please wait..</span></span>'
  },
  lengthMenu: [ [ 20, 50, -1 ], [ 20, 50, 'All' ] ],
  order: [],
  responsive: true,
  autoWidth: false,
  pagingType: 'simple_numbers',
  renderer: 'bootstrap',
  rowGroup: {
    enable: false,
  },
  initComplete: function( e ){
    $( e.nTable ).hasClass( 'enable-rowgroup' ) ? this.api().rowGroup().enable().draw() : null;
    $( e.nTableWrapper ).prev().fadeOut( 300 );
    setTimeout( function(){
      $( e.nTableWrapper ).find( '.invisible' ).fadeIn( 300, function(){
        $( this ).removeClass( 'invisible' );
      } );
    } );
    $( '[data-bs-toggle="tooltip"]' ).tooltip();
  },
  drawCallback: function() {
    $( '[data-bs-toggle="tooltip"]' ).tooltip();
  },
};
var KTDatatablesBasicBasic = {
  init: function(){
    $.extend( $.fn.dataTable.ext.classes, {
      container: 'dt-container dt-bootstrap5 h-100',
      length: {
        container: 'dt-length',
        select: 'form-select form-select-solid form-select-sm'
      },
      processing: {
        container: 'dt-processing'
      },
      search: {
        container: 'dt-search',
        input: 'form-control form-control-solid form-control-sm'
      },
      paging: {
        active: 'current',
        button: 'dt-paging-button',
        container: 'dt-paging py-0',
        disabled: 'disabled'
      },
    } );
    $( '.datatables-net' ).each( function(){
      // The data-visible HTML data attributes do not function in datatables-net 2.x.
      // Therefore, the column definition is modified before initialization.
      var columnDefs = [],
          dataTableSettings,
          dataTable;

      // Collect column visibility settings from the <th> elements.
      $( this ).find( 'thead th' ).each( function( index ){
        var visible = $( this ).data( 'visible' ) !== false; // Default to true if not specified
        columnDefs.push( { targets: index, visible: visible } );
      } );

      // Create a copy of the default settings and include the columnDefs
      dataTableSettings = $.extend( {}, dataTablesDefault, {
        columnDefs: columnDefs
      } );

      dataTable = $( this ).DataTable( dataTableSettings );

      dataTable.on( 'column-visibility.dt', function(){
        // Update the state of "Select all" button based on column visibility
        var columns = dataTable.columns().visible().toArray();
        $( dataTable.table().container() ).find( '.select-all' ).toggleClass( 'dt-button-active', columns.every( Boolean ) );
        $( dataTable.table().container() ).find( '.select-all' ).toggleClass( 'indeterminate', !columns.every( Boolean ) && columns.some( Boolean ) );
      } );

      window.data_tables.push( dataTable );
    } );
  }
};

jQuery( document ).ready( function(){
  window.data_tables = [];
  KTDatatablesBasicBasic.init();
} );
