const $ = window.$;
const bootstrap = window.bootstrap;
const toastr = window.toastr;
const Pickr = window.Pickr;

import flatpickr from 'flatpickr';

'use strict';

export function create_flatpickr( element_selector_array ){
  element_selector_array.forEach( function( el ){
    let $el = $( el ),
        options;

    options = {
      allowInvalidPreload: true,
      dateFormat: $el.data( 'dateonly' ) == true ? 'Y-m-d' : 'Y-m-d h:i K',
      enableTime: $el.data( 'dateonly' ) == true ? false : true,
      minDate: $el.data( 'startdate' ) == 'now' ? roundUpto( 15, convertTZ( $el.data( 'timezone' ) ) ) : undefined,
      minuteIncrement: 1,
      mode: $el.data( 'range' ) == true ? 'range' : 'single',
      time_24hr: false,
      wrap: $el.data( 'wrap' ) == true ? true : false,
      onClose: function( selectedDates, dateStr, instance ) {
        if ( ( instance.config.mode == 'range' ) && ( selectedDates.length == 1 ) ) {
          instance.setDate( [ selectedDates[ 0 ], selectedDates[ 0 ] ], true );
        }
      },
      onOpen: function( selectedDates, dateStr, instance ) {
        if ( ( instance.config.minDate ) && ( selectedDates.length == 0 ) ) {
          instance.set( 'minDate', roundUpto( 15, convertTZ( instance.element.dataset.timezone ) ) );
        }
      }
    };
    flatpickr( $el, options );
  } );
}

export function roundUpto( minutes, d = new Date() ) {
  let ms = 1000 * 60 * minutes;
  return new Date( Math.ceil( d.getTime() / ms ) * ms );
}

export function convertTZ( tzString ) {
  let date = new Date();
  if ( tzString ) {
    return new Date( ( typeof date === "string" ? new Date( date ) : date ).toLocaleString( "en-US", { timeZone: tzString } ) );
  } else {
    return date;
  }
}

// searchable dropdowns
export function create_searchable_dropdowns( element_selector ){
  var obj, parent;
  obj = {
    width: '100%',
    theme: 'bootstrap5',
    selectionCssClass: 'form-select',
    placeholder: 'Please select',
    matcher: customMatcher,
  };

  parent = $( element_selector ).closest( '.modal' );

  if( parent.length ){
    obj[ 'dropdownParent' ] = parent;
  }
  $( element_selector ).select2( obj );
}

function customMatcher( params, data ) {
  var match,
      c,
      child,
      matches,
      original,
      term;
  data.parentText = data.parentText || "";
  if( $.trim( params.term ) === '' ){
    return data;
  }
  if( data.children && data.children.length > 0 ){
    match = $.extend( true, {}, data );
    for( c = data.children.length - 1; c >= 0; c-- ){
      child = data.children[ c ];
      child.parentText += data.parentText + " " + data.text;
      matches = customMatcher( params, child );
      if( matches == null ){
        match.children.splice( c, 1 );
      }
    }
    if( match.children.length > 0 ){
      return match;
    }
    return customMatcher( params, match );
  }
  original = ( data.parentText + ' ' + data.text ).toUpperCase();
  term = params.term.toUpperCase();
  if( original.indexOf( term ) > -1 ){
    return data;
  }
  return null;
}

// required fields
// set_required_fields( 'form', [ { id: '#name', display: 'Name', func: function(){ false; } ] );
export function set_required_fields( form_selector, required_fields ){
  $( form_selector ).on( 'submit', function( e ){
    let err = false;

    $( '.is-invalid', form_selector ).removeClass( 'is-invalid' );

    required_fields.forEach( function( field ){
      let ret = field_check( field );

      if( ret ){ err = true; }
    } );

    if( err ){
      e.preventDefault();
    } else {
      this.submit();
    }
    return !err;

    function field_check( field ){
      let $field = $( field.id ),
          err = false,
          func = field.func || default_blank_check;

      if ( Array.isArray( func( $field ) ) ){
        if( func( $field )[ 0 ] ){
          toastr.error( func( $field )[ 1 ] );
          $field.addClass( 'is-invalid' );

          err = true;
        }
      }
      else{
        if( func( $field ) ){
          toastr.error( field.display + ' is required' );
          $field.addClass( 'is-invalid' );

          err = true;
        }
      }

      return err;
    }

    function default_blank_check( field ){
      return field[ 0 ] && ( field.val() == null || field.val().trim() == '' );
    }
  } );
}

export function overlap( element_selector, event_id, event_tz, start_at, end_at, auto_publish_value, auto_publish_type, auto_destroy_value, auto_destroy_type, auto_publish = null,  auto_destroy = null ) {
  $( element_selector ).hide();
  $.ajax( {
    method: 'get',
    url: '/admin/events/overlap',
    data: {
      event_id: event_id,
      event_tz: event_tz,
      start_at: start_at,
      end_at: end_at,
      auto_publish_value: auto_publish_value,
      auto_publish_type: auto_publish_type,
      auto_destroy_value: auto_destroy_value,
      auto_destroy_type: auto_destroy_type,
      auto_publish: auto_publish,
      auto_destroy: auto_destroy
    },
    success: function( d ){
      if( d.error ){
        $( element_selector ).show().find( '.overlap-content' ).html( d.message );
      }
    },
    error: function( x ){
      switch ( x.status ) {
        case 401:
          session_timeout_30(); // eslint-disable-line no-undef
          break;
        default:
          toastr.error( x.responseText );
      }
    },
  } );
}

export function reset_buttons( selector ) {
  let btn = $( selector )[ 0 ];
  btn.dataset.loading = '';
  btn.disabled = false;
  if( btn.dataset.originalText ) {
    btn.innerText = btn.dataset.originalText;
  }
}

export function toast_errors( errors ){
  if( typeof( errors ) == 'object' ){
    for( let i = 0; i < errors.length; i++ ){
      toastr.error( errors[ i ] );
    }
  } else {
    toastr.error( errors );
  }
}

export function custom_popover( selector ) {
  let popoverSettings =  {
    placement: 'left',
    trigger: 'manual',
    html: true,
    sanitize : false,
    animation: false,
    template: '<div class="popover" role="tooltip"><div class="popover-arrow"></div><div class="popover-body items-popover-body"></div></div>'
  };
  $( 'body' ).on( 'mouseenter', selector, function() {
    var _this = this;
    $( _this ).popover( popoverSettings );
    $( _this ).popover( 'show' );
    $( bootstrap.Popover.getInstance( $( this ) ).tip ).on( 'mouseleave', function() {
      setTimeout( function() {
        $( _this ).popover( 'hide' );
      }, 100 );
    } );
  } ).on( 'mouseleave', selector, function() {
    var _this = this;
    $( _this ).popover( popoverSettings );
    setTimeout( function() {
      if ( !$( '.popover:hover' ).length ) {
        if ( !$( _this ).is( ':hover' ) ) {
          $( _this ).popover( 'hide' );
        }
      }
    }, 100 );
  } );
}

export function color_pickr( element_selector_array ) {
  element_selector_array.forEach( function( el ){

    let $input = $( el ),
        current_color = $input.val(),
        pickr;

    if( !$input[ 0 ] ){
      return;
    }
    if ( !current_color && $input.hasClass( "required" ) ){
      current_color = Math.floor( Math.random()*16777215 ).toString( 16 ).padStart( 6, '0' );
    }

    pickr = new Pickr( {
      el: $input.parent().find( '.pickr' )[ 0 ],
      theme: 'monolith',
      swatches: null,
      defaultRepresentation: 'HEX',
      default: current_color,
      comparison: true,
      lockOpacity: true,
      components: {
        preview: true,
        opacity: false,
        hue: true,
        interaction: {
          hex: true,
          rgba: false,
          hsva: false,
          input: true,
          clear: false,
          cancel: true,
          save: true,
        }
      }
    } )
    .on( 'init' , function( instance ) {
      current_color = getSelectedColor( instance );
      $input.val( current_color ).trigger( 'change' );
    } )
    .on( 'cancel' , function( instance ) {
      current_color = getSelectedColor( instance );
      $input.val( current_color ).trigger( 'change' );
    } )
    .on( 'change' , function( color ) {
      let new_color = color
        .toHEXA()
        .toString();
      $input.val( new_color ).trigger( 'change' );
    } );

    function getSelectedColor( instance ){
      let selected_color = '';
      if( current_color ){
        selected_color = instance.getSelectedColor().toHEXA().toString();
      }
      return selected_color;
    }

    $input.on( 'focus', function() {
      pickr.show();
    } );

    $input.on( 'keyup change', function() {
      $( $input ).parent().find( '.pcr-button' ).css( '--pcr-color', $input.val() );
    } );

  } );
}
