import { Controller } from '@hotwired/stimulus';
import { Turbo } from "@hotwired/turbo-rails";
import 'datatables.net';
import 'datatables.net-bs5';
import 'datatables.net-buttons';
import 'datatables.net-buttons-bs5';
import 'datatables.net-responsive';
import 'datatables.net-responsive-bs5';
import 'datatables.net-rowgroup';
import 'datatables.net-rowgroup-bs5';
import 'datatables.net-select-bs5';
import 'datatables.net-buttons/js/buttons.colVis.min';
import 'datatables.net-buttons/js/buttons.html5.min';
import { dataTablesDefault } from 'datatables_net';
import * as swal from 'sweetalert2';

// Connects to data-controller="resources"
export default class extends Controller {

  static targets = [ 'dropdownButton', 'form', 'resource', 'selectAll', 'table' ];

  connect() {
    this.bulkIds = [];

    $.extend( $.fn.dataTable.ext.classes, {
      container: 'dt-container dt-bootstrap5 h-100',
      length: {
        container: 'dt-length',
        select: 'form-select form-select-solid form-select-sm'
      },
      processing: {
        container: 'dt-processing text-gray-600'
      },
      search: {
        container: 'dt-search',
        input: 'form-control form-control-solid form-control-sm'
      },
      paging: {
        active: 'current',
        button: 'dt-paging-button',
        container: 'dt-paging py-0',
        disabled: 'disabled'
      },
    } );

    const element = this.tableTarget;

    // The data-visible HTML data attributes do not function in datatables-net 2.x.
    // Therefore, the column definition is modified before initialization.
    const columnDefs = [];

    // Collect column visibility settings from the <th> elements.
    $( element ).find( 'thead th' ).each( ( index, th ) => {
      const visible = $( th ).data( 'visible' ) !== false; // Default to true if not specified
      const columnDef = { targets: index, visible: visible };
      if( $( element ).hasClass( 'datatables-select' ) && index === 0 ){
        columnDef.render = $.fn.dataTable.render.select();
      }
      columnDefs.push( columnDef );
    } );

    // Create a copy of the default settings and include the columnDefs
    const dataTableSettings = $.extend( {}, dataTablesDefault, {
      columnDefs: columnDefs,
    } );

    if( $( element ).hasClass( 'datatables-select' ) ) {
      // Manage visibility for all columns except the select column
      const colvisButton = dataTablesDefault.buttons.buttons.find( button => button.extend === 'colvis' );
      if( colvisButton ){
        colvisButton.columns = ':gt(0)';
      }

      // Add select settings
      dataTableSettings.select = {
        selector: 'td:first-child',
        style: 'multi',
      };
    }

    const dataTable = $( element ).DataTable( dataTableSettings );

    dataTable.on( 'column-visibility.dt', () => {
      // Update the state of "Select all" button based on column visibility
      const columns = dataTable.columns().visible().toArray();
      const container = $( dataTable.table().container() );
      container.find( '.select-all' ).toggleClass( 'dt-button-active', columns.every( Boolean ) );
      container.find( '.select-all' ).toggleClass( 'indeterminate', !columns.every( Boolean ) && columns.some( Boolean ) );
    } );

    dataTable.on( 'select deselect', ( e, dt, type, indexes ) => { // eslint-disable-line no-unused-vars
      // Iterate over the selected rows
      const selectedData = dt.rows( '.selected' ).data();
      this.bulkIds = []; // Clear the bulkIds array before populating it
      const releaseButton = $( '.bulk-release' );

      if( selectedData.length > 0 ){
        releaseButton.removeClass( 'disabled' );
      } else {
        releaseButton.addClass( 'disabled' );
      }

      selectedData.each( ( rowData ) => {
        let htmlContent = rowData[ 8 ],
            resourceIdMatch = htmlContent.match( /data-resource-id="(\d+)"/ ),
            batchIdMatch = htmlContent.match( /data-batch-id="(\d+)"/ );

        if( resourceIdMatch && batchIdMatch ){
          let resourceId = resourceIdMatch[ 1 ],
              batchId = batchIdMatch[ 1 ];
          this.bulkIds.push( { resource_id: resourceId, batch_id: batchId } );
        }
      } );
    } );
  }

  resourceTargetConnected() {
    this.updateDropdownButton();
    this.updateSelectAllCheckbox();
    this.updateCheckboxOnLoad();
  }

  resourceTargetDisconnected() {
    this.formTarget.removeAttribute( 'data-turbo-permanent' );
    this.updateDropdownButton();
    this.updateSelectAllCheckbox();
  }

  toggleSelectAll() {
    const allChecked = this.selectAllTarget.checked;
    this.resourceTargets.forEach( checkbox => {
      checkbox.checked = allChecked;
    } );
    this.updateDropdownButton();
    this.submitForm();
  }

  toggleResource() {
    this.updateSelectAllCheckbox();
    this.updateDropdownButton();
    this.submitForm();
  }

  updateCheckboxOnLoad() {
    const resourceIdElement = this.element.querySelector( '[data-resource-ids]' );
    const resourceIdString = resourceIdElement.getAttribute( 'data-resource-ids' );

    if( resourceIdString ) {
      const resourceIds = JSON.parse( resourceIdString );
      this.resourceTargets.forEach( checkbox => {
        checkbox.checked = resourceIds.includes( Number( checkbox.value ) );
      } );
    }
    this.updateDropdownButton();
  }

  updateSelectAllCheckbox() {
    const totalResources = this.resourceTargets.length;
    const checkedResources = this.resourceTargets.filter( checkbox => checkbox.checked ).length;

    if( checkedResources === totalResources ){
      this.selectAllTarget.checked = true;
      this.selectAllTarget.indeterminate = false;
    } else if( checkedResources === 0 ){
      this.selectAllTarget.checked = false;
      this.selectAllTarget.indeterminate = false;
    } else {
      this.selectAllTarget.checked = false;
      this.selectAllTarget.indeterminate = true;
    }
  }

  updateDropdownButton() {
    const totalResources = this.resourceTargets.length;
    this.dropdownButtonTarget.disabled = totalResources === 0;
  }

  submitForm() {
    this.formTarget.requestSubmit();
  }

  bulkRelease( event ) {
    event.preventDefault();
    event.currentTarget.blur();

    const url = event.currentTarget.dataset.resourcesUrl;

    swal.fire( {
      cancelButtonText: "Cancel",
      confirmButtonColor: "#d9534f",
      confirmButtonText: "Yes, release it.",
      icon: "warning",
      preConfirm: async() => {
        try {
          await this.makeTurboStreamRequest( url, 'PATCH', { resources: this.bulkIds } );
          event.target.classList.add( 'disabled' );
        } catch( error ) {
          event.target.classList.remove( 'disabled' );
        }
      },
      reverseButtons: true,
      showCancelButton: true,
      showLoaderOnConfirm: true,
      title: 'Are you sure you want to release <strong>selected resources</strong>',
    } );
  }

  confirmPopup( event ) {
    event.preventDefault();
    event.currentTarget.blur();

    const url = event.currentTarget.getAttribute( 'href' );
    const title = event.currentTarget.dataset.alertTitle;
    const confirm = event.currentTarget.dataset.confirmTitle;
    const method = event.currentTarget.dataset.turboMethod.toUpperCase();

    swal.fire( {
      cancelButtonText: "Cancel",
      confirmButtonColor: "#d9534f",
      confirmButtonText: confirm,
      icon: "warning",
      preConfirm: async() => {
        await this.makeTurboStreamRequest( url, method );
      },
      reverseButtons: true,
      showCancelButton: true,
      showLoaderOnConfirm: true,
      title: title,
    } );
  }

  async makeTurboStreamRequest( url, method, body = null ) {
    const csrfToken = document.querySelector( "meta[name='csrf-token']" ).content;
    const options = {
      credentials: 'same-origin',
      headers: {
        'Accept': 'text/vnd.turbo-stream.html',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken,
      },
      method: method,
    };
    if( body ) {
      options.body = JSON.stringify( body );
    }
    try {
      const response = await fetch( url, options );
      const data = await response.text();
      const doc = this.parseTurboStream( data ); // Parse turbo stream response
      this.renderTurboStream( doc ); // Render turbo stream
    } catch ( error ) {
      window.toastr.error( 'Unknown error has occurred' );
      console.error( 'Error:', error );
    }
  }

  parseTurboStream( data ) {
    const parser = new DOMParser();
    return parser.parseFromString( data, 'text/html' );
  }

  renderTurboStream( doc ) {
    const turboStreamElements = doc.querySelectorAll( 'turbo-stream[action]' );

    turboStreamElements.forEach( turboStream => {
      const action = turboStream.getAttribute( 'action' );
      const target = turboStream.getAttribute( 'target' );

      if( target.includes( 'datatables' ) ) {
        const table = $( $( `#${ target }` ) ).closest( 'table' );
        const dataTable = table.DataTable();
        const row = $( `#${ target }` );
        if( action === 'remove' ){
          if( row.length > 0 ) {
            // Remove the row from the DataTable
            dataTable.row( row ).remove().draw( false );
          }
        } else if( action === 'replace' ) {
          if( row.length > 0 ){
            const template = turboStream.querySelector( 'template' );

            // Remove the row from the DataTable
            dataTable.row( row ).remove().draw( false );

            if( template ){
              // Add the new row to the DataTable
              dataTable.row.add( $( template.innerHTML ) ).draw( false );
            }
          }
        }
      } else {
        Turbo.renderStreamMessage( turboStream.outerHTML );
      }
    } );
  }
}
