// Metronic

// Imports
import Swal from 'sweetalert2';
window.swal = Swal;

import { marked } from 'marked';
marked.use( {
  mangle: false,
  headerIds: false,
} );
window.marked = marked;

// Global
window.jQuery = window.$ = require( 'jquery' );
require( 'jquery-ui-dist/jquery-ui.js' );
import 'jquery-ui-dist/jquery-ui.css';
window.Popper = require( '@popperjs/core' );
window.bootstrap = require( 'bootstrap' );
require( 'jquery-migrate' );
jQuery.migrateMute = true;
window.serializeJSON = require( 'jquery-serializejson' );

// Sweetalert2
require( 'es6-promise-polyfill/promise.min.js' );

// Block-ui
require( 'block-ui' );

// Bootstrap Markdown & markdown.js
import 'bootstrap-markdown/css/bootstrap-markdown.min.css';
require( 'bootstrap-markdown/js/bootstrap-markdown' );
$.fn.markdown.defaults.iconlibrary = 'fa';
$.fn.markdown.defaults.buttons[ 0 ][ 0 ][ 'data' ][ 2 ][ 'icon' ][ 'fa' ] = 'fa fa-heading';
$.fn.markdown.defaults.buttons[ 0 ][ 1 ][ 'data' ][ 1 ][ 'icon' ][ 'fa' ] = 'fa fa-image';
$.fn.markdown.defaults.buttons[ 0 ][ 2 ][ 'data' ][ 1 ][ 'icon' ][ 'fa' ] = 'fa fa-list-ol';

// Toastr
import 'toastr/build/toastr.css';
window.toastr = require( 'toastr' );

// jstree
require( 'jstree' );
import 'jstree/dist/themes/default/style.css';

// Bootstrap Maxlength
require( 'bootstrap-maxlength' );

// select2
require( 'select2' );

// Devbridge Autocomplete
require( 'devbridge-autocomplete' );

// jQuery Inputmask
require( 'inputmask/dist/jquery.inputmask.min.js' );

// Bootstrap Rating
require( 'bootstrap-rating' );

// Momentjs
window.moment = require( 'moment' );

// Sentry
window.Sentry = require( '@sentry/browser' );

// ACE
require( 'ace-builds/src-noconflict/ace' );
require( 'ace-builds/src-noconflict/theme-github' );
require( 'ace-builds/src-noconflict/mode-batchfile' );
require( 'ace-builds/src-noconflict/mode-markdown' );
require( 'ace-builds/src-noconflict/mode-powershell' );
require( 'ace-builds/src-noconflict/mode-ruby' );
require( 'ace-builds/src-noconflict/mode-sh' );
require( 'ace-builds/src-noconflict/mode-text' );
require( 'ace-builds/src-noconflict/mode-yaml' );
require( 'ace-builds/webpack-resolver' );

// jQuery Validation
require( 'jquery-validation' );
require( 'jquery-validation/dist/additional-methods.js' );

// Dual-listbox
import { DualListbox } from "dual-listbox/src/dual-listbox";
window.DualListbox = DualListbox;
require( "dual-listbox/dist/dual-listbox.css" );

// Mjml
import mjml from 'mjml-browser';
window.mjml2html = mjml;

// @simonwep/pickr
import Pickr from "@simonwep/pickr";
window.Pickr = Pickr;

// @yaireo/tagify
import Tagify from '@yaireo/tagify';
window.Tagify = Tagify;
